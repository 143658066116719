import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/thomas-lee-morris.png";
  const fullName = `Thomas "Tommy" Lee Morris`;
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const zoomMeetingPasscode = "";
  // const birthDate = new Date(2020, 12, 1);
  const birthDate = "August 27, 1936";
  // const deathDate = "December 5, 2020";
  const deathDate = "December 20, 2020";
  // const memorialDate = new Date(2021, 1, 5, 10, 30, 0); // "Tuesday January 5th, 2021";
  const memorialDate = "Tuesday January 5th, 2021";
  //   const memorialTime = new Date(2021, 1, 5, 10, 30, 0);  // "Tuesday January 5th, 2021";
  const memorialTime = "10:30AM EST";
  const inviteText = ``;
  const obituary = `On Sunday, December 20, 2020, Thomas "Tommy" Lee Morris passed peacefully, at Quality Comfort Care, an assisted living home, in Gaithersburg, Maryland. Thomas was born August 27, 1936 in Burkittsville, Maryland to Robert Ambrose Morris and Fannie Butler Morris.
  \nOn August 24, 1956, Thomas enlisted in the United States Army and served overseas in Korea. Upon his return to the States he was stationed in various states including Alaska and Kentucky, before returning to Maryland. Thomas received the National Defense Service Medal and was Honorably discharged on November 24th, 1961. After discharge, Thomas was nominated and accepted as a member of the American Legion.
  \nOn June 25, 1959, Thomas married his loving wife Sylvia Jean Campbell. To this union came 2 children Thomas J. Morris and Ruth Ann Minor. Additionally, Thomas and Sylvia played a significant part in the lives of children in the community.
  \nThomas would describe himself as a lifelong farmer and an avid viewer of Western Movies. In fact, the week before passing Thomas described feeling tired from "planting tomatoes in the garden." Not only was Thomas a farmer, he also worked for the Frederick County School District, as the Assistant Lead Custodian, retiring after 20 years of service. He was also a member of St. Mary's Catholic Church, in Petersville.
  \nPreceding Thomas in death were his wife Sylvia Jean Morris, his Mother Fannie Mae Butler, his Father Robert Ambrose Morris, his brothers Charles A. Morris, Sylvester J. Morris, Paul Morris, Bernard O. Morris, and Joseph Morris, and his sister Fannie Marietta Lyles.
  \nThomas is survived by his son Thomas Morris ( wife Jonelle Rothwell-Morris) Ruth Ann Minor ( Husband John Minor Sr. ), grandchildren Thomas D. Morris, Hasaan D. Morris, Mariah R. Morris, Khrystina O. Minor, Dejah Minor, and John Minor Jr., brothers John Henry Morris and Robert Morris, and a host of nieces, nephews, cousins, and close family friends.
  \nFuneral Services will be held for Thomas at Resthaven Memorial Gardens 9501 Catoctin Mountain Hwy, Frederick Maryland 21701, on Tuesday January 5th, at 10:30 am. The viewing for Thomas will be held on Monday, January 4th from 12:00pm-4:00pm. Reverend Thelma Lyles-Nolan will be officiating.`;
  const storiesRecordUrl = "https://stories.afterword.com/f2xd2nxtu";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rmq2k1s288gkb70qkpigwxrcaw437njyb87mm9at";
  const recordingEmbedUrl = "";
  const recordingEmbedDirectUrl = [];

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
    ></StoryPage>
  );
};

export default ThisPage;
